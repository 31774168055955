//
// slidize.js
//

const themeSansSerif = document.getElementById('themeSansSerif');
const familySansSerif = window.localStorage.getItem('goodkitSansSerif');

function switchFamily(checked) {
  window.localStorage.setItem('goodkitSansSerif', checked);
  themeSansSerif.disabled = !checked;
  toggleSwitches(checked);
}

function toggleSwitches(checked) {
  const familySwitches = document.querySelectorAll('.family-switch');

  familySwitches.forEach((familySwitch) => {
    familySwitch.checked = checked;
  });
}

if (familySansSerif === 'true') {
  themeSansSerif.disabled = false;
}

window.addEventListener('DOMContentLoaded', () => {
  toggleSwitches(familySansSerif === 'true');
  
  document.querySelectorAll('pre code').forEach((block) => {
    hljs.highlightBlock(block);
  });   
});

// Make global
window.switchFamily = switchFamily;
